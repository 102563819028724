import React from "react";

const App = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-900 to-purple-700 text-white">
      {/* Header */}
      <header className="bg-purple-800 text-center py-6 shadow-lg">
        <h1 className="text-3xl font-bold text-white">Canva Pro by Chill Guy</h1>
      </header>

      {/* Main Content */}
      <main className="max-w-3xl mx-auto px-4 py-8">
        {/* Buttons Row */}
        <div className="flex flex-col md:flex-row md:space-x-4 justify-center mb-6">
          <a
            href="https://google.com"
            className="w-full md:w-auto mb-4 md:mb-0 px-6 py-3 text-center bg-gradient-to-r from-purple-600 to-purple-500 rounded-full shadow-lg hover:scale-105 transform transition">
            Join Canva Pro
          </a>
          <a
            href="https://google.com"
            className="w-full md:w-auto px-6 py-3 text-center bg-gradient-to-r from-purple-600 to-purple-500 rounded-full shadow-lg hover:scale-105 transform transition">
            Tutorial Join
          </a>
        </div>

        {/* Telegram Section */}
        <div className="text-center bg-purple-800 p-6 rounded-lg mb-6 shadow-lg">
          <p className="mb-4 text-lg">
            Kalian bisa join ke Telegram Chill Guy biar tau update-update ke depannya.
          </p>
          <a
            href="https://google.com"
            className="px-6 py-3 bg-gradient-to-r from-purple-600 to-purple-500 rounded-full shadow-lg hover:scale-105 transform transition">
            Join Telegram
          </a>
        </div>

        {/* Donation Section */}
        <div className="text-center bg-purple-800 p-6 rounded-lg shadow-lg">
          <p className="mb-4 text-lg">
            Kalian bisa donasi biar Chill Guy terus menyediakan Canva Pro, atau mungkin akses
            aplikasi pro lainnya.
          </p>
          <a
            href="https://google.com"
            className="px-6 py-3 bg-gradient-to-r from-purple-600 to-purple-500 rounded-full shadow-lg hover:scale-105 transform transition">
            Saweria
          </a>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-purple-900 text-center py-4 text-sm text-purple-300">
        &copy; 2024 Chill Guy. Made with ❤️ for all creators.
      </footer>
    </div>
  );
};

export default App;
